import React, { useEffect } from 'react';
import './index.scss';
// import logo_icon from '../../../static/imgs/logo_icon.png'
import logo_icon from '../../../static/imgs/logo2.png';
import logo_icon3 from '../../../static/imgs/logo3.png';

import phone_icon from '../../../static/imgs/phone.svg';
import phone_icon2 from '../../../static/imgs/phone2.svg';
import classNames from 'classnames';
import { useScroll } from 'lib/useScroll';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LINK_MODE} from "@/lib/utils";

const ENV_MODE = process.env.REACT_APP_MODE;

const URL_PREFIX_SSR = {
	dev: 'https://xgw-pre.jdazcn.com',
	test: 'https://xgw-pre.jdazcn.com',
	pre: 'https://xgw-pre.jdazcn.com',
	prod: 'https://www.jdallianz.com',
}[ENV_MODE];

const menuData = [
  {
    name: '首页',
    link: `${LINK_MODE}/`,
    link_path: `/`,
    id: '1',
  },
  {
    name: '保险商城',
    link: `${LINK_MODE}/bxsc`,
    link_path: `/bxsc`,
    id: '2',
  },
  {
    name: '保险服务',
    link: `${LINK_MODE}/insurance-server`,
    link_path: `/insurance-server`,
    id: '3',
  },
	{
		name: '开放平台',
		link: `https://partner.jdallianz.com/`,
		link_path: ``,
		id: 'apis',
	},
  {
    name: '新闻资讯',
    link: `${LINK_MODE}/news-list`,
    link_path: `/news-list`,
    id: '4',
  },
	{
		name: '内容社区',
		link: `${URL_PREFIX_SSR}/pgc/list`,
		link_path: '/pgc/list',
		id: 'pgc',
		pathGroup: ['/pgc/list', '/pgc/detail']
	},
  {
    name: '关于我们',
    link: `${LINK_MODE}/about-us/comp-info`,
    link_path: `/about-us/comp-info`,
    id: '5'
  },
];

const topMenuData = [
  {
    name: '公开信息披露',
    link: `${LINK_MODE}/public-info/base-info/company-profile`,
    id: 'gkpl',
  },
  {
    name: '互联网保险信息披露',
    link: `${LINK_MODE}/public-info/special-info/internet-insurance`,
    id: 'xxpl',
  },
  {
    name: '投诉处理流程',
    link: `${LINK_MODE}/suggestion/complaint`,
    id: 'tscl',
  },
  // {
  //   name: '关注我们',
  //   link: '/gzwm',
  //   id: 'gzwm',
  // },
];
const Navbar: React.FC = () => {
  let location = useLocation();

  const isScrolling = useScroll(10);

	useEffect(() => {
		try {
			console.log('location.pathname', location?.pathname);
			document.body.style.overflow = 'visible';
		} catch (e) {

		}
	}, [location?.pathname])

  return (
    <div
      className={classNames(
        'navbar_wrap',
        location.pathname !== '/' || isScrolling ? 'scroll_bg' : '',
        isScrolling ? 'scroll_height' : ''
      )}
    >
      <div className='top_line flex-end'>
        {topMenuData.map((topMenu, index) => (
          <div className='flex-between' key={index}>
            {topMenu?.link.startsWith('https') ||
            topMenu?.link.startsWith('http') ? (
              <a href={topMenu.link} rel='noreferrer'>
                {topMenu.name}
              </a>
            ) : (
              <NavLink
                to={topMenu.link}
                className={({ isActive }) => (isActive ? 'selected' : '')}
              >
                {topMenu.name}
              </NavLink>
            )}
            {index !== topMenuData.length - 1 && (
              <div className='divider'></div>
            )}
          </div>
        ))}
      </div>

      <div className='navbar_inner flex_start'>
        <Link to={menuData[0]['link']}>
          <img
            src={
              location.pathname !== '/' || isScrolling ? logo_icon3 : logo_icon
            }
            className='logo_icon'
            alt=''
          />
        </Link>
        <menu className='navbar_menu hander'>
          {menuData.map((menu, index) => {
            return (
              <div className='narbar_item' key={index}>
                {menu?.link.startsWith('https') ||
                menu?.link.startsWith('http') ? (
                  <a
                    href={menu.link}
                    key={menu.id}
                    className={location.pathname === menu.link_path ? "activeNav" : ""}
                    rel='noreferrer'
                  >
                    {menu.name}
                  </a>
                ) : (
                  <NavLink
                    to={menu.link}
                    className={({ isActive }) => (isActive ? 'activeNav' : '')}
                    key={menu.id}
                  >
                    {menu.name}
                  </NavLink>
                )}
                {/* <div className='active_line'></div> */}
              </div>
            );
          })}
        </menu>
        <div className='hot-line-wrap '>
          <img
            src={
              location.pathname !== '/' || isScrolling
                ? phone_icon
                : phone_icon2
            }
            className='hot-line-icon'
            alt=''
          ></img>
          <span className='phone-label'>全国统一服务热线：</span>
          <span className='phone-value'>950610</span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
